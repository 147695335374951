import { graphql, Link, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import React, { useCallback, useMemo, useState } from "react"
import { useContextValues } from "../context/NavBarContext"

const Technologies = () => {
  let {
    allTechnologiesImagesJson: { Technologies }
  } = useStaticQuery(graphql`
    query {
      allTechnologiesImagesJson(
        filter: {
          key: {
            in: [
              "angular"
              "react"
              "node"
              "swift"
              "vue"
              "kotlin"
              "flutter"
              "laravel"
              "mongo"
              "mysql"
            ]
          }
        }
      ) {
        Technologies: nodes {
          key
          name
          slug
          description
          alt
          image {
            publicURL
            extension
            name
          }
        }
      }
    }
  `)
  const [activeTab, setActiveTab] = useState(Technologies[0].key)

  const { isMobile } = useContextValues()
  const getActiveTabClass = useCallback(
    key => {
      if (key === activeTab) {
        return ` before:absolute before:w-full before:h-1 before:bg-primary before:content-[''] before:bottom-[0.25rem] before:z-10`
      }
      return ""
    },
    [activeTab]
  )

  const positionActiveTab = (event, key) => {
    var parent = event.target.parentElement
    var child = event.target
    parent.scrollLeft = (child.offsetWidth + 30) * key - 70
  }
  const tabInfo = useMemo(() => {
    const { key, name, description, image, slug, alt } = Technologies.find(
      item => item.key === activeTab
    )
    return {
      key,
      name,
      image,
      description: description,
      slug,
      alt,
    }
  }, [activeTab])

  return (
    <>
      <div className="py-[3.75rem] lg:py-20 relative">
        <div className="container relative z-10">
          <div className="w-full pb-12 title-sec text-center lg:max-w-[50rem] m-auto">
            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
              Unlocking Business Success by Integrating <span className="italic text-primary">Agile Technology</span>
              <span className="inline-block text-orangecol">.</span>
            </h2>
            <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">We have the technical prowess of industry-leading programming languages, frameworks, and tools.  And we’ll help you implement strategically fit tech stack for maximum reliability, flexibility, and scalability.</p>
          </div>
          {isMobile
            ? <div className="mb-10 w-full relative before:absolute before:content-[''] before:w-full before:h-px before:bg-[#cfc8ca] before:left-0 before:bottom-[0.313rem]">
              <ul className="flex md:justify-between justify-start items-center overflow-y-hidden overflow-x-auto w-full px-1">
                {Technologies.map((item, idx) => (
                  <li
                    key={`mobile-tab${item.key}`}
                    onClick={e => {
                      positionActiveTab(e, idx)
                      setActiveTab(item.key)
                    }}
                    className={`mx-5 whitespace-nowrap relative pb-4${getActiveTabClass(
                      item.key
                    )} transition-all ease-in-out duration-1000 md:transform translate-x-full slide transform-none`}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
            : <ul className="flex-wrap items-center justify-center flex max-w-[59.375rem] m-auto">
              {Technologies.map(({ key, image, alt }) => {
                if (!(image.extension === "svg")) image = getImage(image)
                return (
                  <li
                    key={`web-content-${key}`}
                    onClick={() => setActiveTab(key)}
                    className={`flex h-[8.125rem] w-1/5 items-center justify-center cursor-pointer rounded-[0.875rem] transition-all hover:bg-white-200 hover:shadow-[0px 9px 22.1px 0px rgba(87, 57, 13, 0.07);] duration-500${activeTab === key ? " bg-white-200 shadow-[0px 9px 22.1px 0px rgba(87, 57, 13, 0.07);]" : ""
                      }`}
                  >
                    <img
                      loading="lazy"
                      src={image.publicURL}
                      alt={alt}
                      className="w-12"
                    />
                  </li>
                )
              })}
            </ul>}
          {tabInfo && (
            <div className="tabcon relative md:pt-8 pt-0 md:px-0 px-5 md:pb-0 pb-0 md:bg-transparent bg-transparent md:rounded-none">
              <figure className="shadow-10xl left-1/2 -top-[2.875rem] text-center md:hidden flex items-center justify-center w-[212px] h-[140px] bg-white-200 rounded-[10px] mx-auto my-0 mb-5">
                <img
                  loading="lazy"
                  src={tabInfo.image.publicURL}
                  alt={tabInfo.description}
                />
              </figure>
              <h3 className="pb-[0.625rem] font-blancomedium leading-[2.188rem] text-center text-black text-2xl">
                <Link className="hover:text-primary" to={`${tabInfo.slug}/`}>{tabInfo.name}</Link>
              </h3>
              <p className="text-base leading-[1.625rem] text-center lg:w-4/5 lg:m-auto tracking-wide">
                {tabInfo.description}
              </p>
            </div>
          )}
        </div>
        <StaticImage src="../assets/img/tech-bg.webp" alt="" className="absolute w-full h-full top-0" />
      </div>
    </>
  )
}

export default Technologies
