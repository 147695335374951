import { graphql, HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import animatedIcon from "../assets/img/Contact-us-animation4.gif"
import animatedIconWhite from "../assets/img/Contact-us-animation-white.gif"

import SEO from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"
import ArrowWhite from "../assets/img/arrow-right.webp"
import Faq from "../components/PPC/ReactDevPpc/Faq"
import Contact from "../components/Contact"
import Technologies from "../components/Technologies"
import useContactFormControls from "../hooks/useContactFormControls"

import { Budget, Email, FlagsDropDown, Name, PhoneNumber, Recaptcha, TextArea } from "../components/FormFields"
import TestimonialSlider from "../components/DashboardTestimonials/TestimonialSlider"
import useToggleFaq from "../hooks/useToggleFaq"
import PortfolioDetail from "../components/DashboardPortfolio/PortfolioDetail"
import BannerSlider from "../components/BannerSlider"

const PseoWebDevelopmentCompanyPage = ({
    data: {
        pseoWebDevelopmentCompanyJson: post,
        allServicesPortfolioJson: { portfolio },
    },
}) => {
    const {
        handleChange,
        handleSubmit,
        shouldRenderRecaptcha,
        errorMessage,
        formValue,
        handleToken,
        flags,
        recaptchaRef,
        customPhoneDropdownRef,
        selectedFlagRef,
        toggleDropdown,
        setToggleDropdown,
        selctedCountryFlag,
        setSelectedCountryFlag,
        setSelectedCountryDialCode,
    } = useContactFormControls()

    const { readMore, getToggleMeClass } = useToggleFaq()

    return (
        <>
            <section className="pt-[7.5rem] pb-10 bg-[#F8F7F3]">
                <div className="container">
                    <div className="contact-grid grid lg:grid-cols-2 xl:grid-cols-[1fr_460px] gap-5 lg:gap-[5.375rem] text-center lg:text-left">
                        <div className="company-detail lg:pt-[3.375rem] max-[420px]:text-center">
                            <h1 className="text-black text-[2.375rem] leading-[3.25rem] pb-7 lg:text-[3.125rem] xl:text-[3.25rem] xl:leading-[3.75rem] lg:pb-[2.313rem] font-blancomedium max-[380px]:text-4xl max-[380px]:leading-10">
                                {post.title_1.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                <span className="inline-block text-orangecol">.</span>
                            </h1>
                            <div className="text-base md:text-lg leading-6 md:leading-[1.875rem] font-gorditaregular text-black pb-5 lg:pb-10">
                                <>
                                    {readMore[post.slug]
                                        ? post.description_1.map((desc, idx) => (
                                            <p key={idx}>{desc}</p>
                                        ))
                                        : post.description_1[0].slice(0, 300)}
                                    <span
                                        onClick={() => getToggleMeClass(post.slug, true)}
                                        className="underline transition-all cursor-pointer italic text-[#0B57D0] capitalize hover:text-primary"
                                    >
                                        {readMore[post.slug] ? " show less" : " read more"}
                                    </span>
                                </>
                                {/* We specialize in full-scale web development services that help businesses improve their digital footprints, increase conversion rates, and generate leads...
                                <Link
                                    to=""
                                    className="text-base md:text-lg leading-6 md:leading-[1.875rem] font-gorditaregular text-[#0B57D0] text-center underline italic hover:no-underline">
                                    Read More
                                </Link> */}
                            </div>
                            <div className="tech-achievement grid max-sm:grid-cols-2 sm:grid-cols-3 sm:justify-center md:grid-cols-[1fr_122px_1fr_1fr] gap-[2.75rem]">
                                <div className="upwork">
                                    <p className="text-black text-[1.625rem] font-gorditamedium">30,000+</p>
                                    <span className="block text-[0.688rem] font-gorditaregular my-[0.125rem] mx-auto w-full max-w-[6.125rem] leading-normal">hours of work delivered on</span>
                                    <StaticImage
                                        src="../assets/img/upwork.webp"
                                        alt="Upwork"
                                        placeholder="blurred"
                                        className="block w-[6.125rem] max-lg:mx-auto"
                                    />
                                </div>
                                <div className="clutch-rating">
                                    <div className="text-black text-[1.625rem] font-gorditamedium">4.9/5
                                        <StaticImage
                                            src="../assets/img/rating-stars.webp"
                                            alt="Reviewed rating"
                                            placeholder="blurred"
                                            className="inline-block w-[2.813rem] align-middle ml-[0.375rem] max-lg:mx-auto"
                                        />
                                    </div>
                                    <span className="block text-xs leading-[0.938rem] font-gorditaregular mt-[0.188] mb-[0.375rem]"><strong className="font-gorditamedium">41</strong> verified reviews</span>
                                    <StaticImage
                                        src="../assets/img/clutch.webp"
                                        alt="Verified reviews"
                                        placeholder="blurred"
                                        className="block w-[6.125rem] mx-auto"
                                    />
                                </div>

                                <div className="projects-delivered">
                                    <p className="text-black text-[1.625rem] font-gorditamedium">250+</p>
                                    <span className="inline-block text-xs leading-[0.938rem] font-gorditaregular mt-[0.188] max-w-[106px]">projects delivered to client across globe</span>
                                </div>

                                <div className="industry-exp">
                                    <p className="text-black text-[1.625rem] font-gorditamedium">10+</p>
                                    <span className="inline-block text-xs leading-[0.938rem] font-gorditaregular mt-[0.188] max-w-[106px]">years in the design and development industry</span>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form-part max-lg:max-w-[500px] max-lg:mx-auto">
                            <div className="form-wrapper bg-white-200 rounded-[1.25rem] px-7 py-[1.875rem] md:pt-8 md:px-10 md:pb-11 shadow-xl">
                                <h2 className="text-center font-blancomedium text-xl md:text-2xl leading-normal text-black mb-[1.875rem] md:mb-[2.188rem]">Share your website requirement</h2>

                                <form action="" onSubmit={handleSubmit} onChange={shouldRenderRecaptcha}>
                                    <div className="form-grid grid grid-cols-1 gap-x-5 gap-y-[28px]">
                                        <div className="form-group max-sm:col-span-2 relative">
                                            <Name
                                                fieldCss={`${"text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2]"
                                                    } focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none`}
                                                errorCss="text-[0.75rem] text-red absolute bottom-[-24px]"
                                                formValue={formValue}
                                                handleChange={handleChange}
                                                errorMessage={errorMessage}
                                            />
                                        </div>
                                        <div className="form-group max-sm:col-span-2 relative">
                                            <Email
                                                fieldCss={`${"text-sm w-full text-black py-3 px-5 max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2]"
                                                    } focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none`}
                                                errorCss="text-[0.75rem] text-red absolute lg:bottom-[-24px] bottom-0"
                                                formValue={formValue}
                                                handleChange={handleChange}
                                                errorMessage={errorMessage}
                                            />
                                        </div>
                                        <div className="form-group col-span-2">
                                            <div className="relative">
                                                <FlagsDropDown
                                                    selectedFlagRef={selectedFlagRef}
                                                    setToggleDropdown={setToggleDropdown}
                                                    selctedCountryFlag={selctedCountryFlag}
                                                />
                                                <PhoneNumber
                                                    fieldCss={`${"text-sm w-full text-black py-3 px-5 pl-[47px] max-[1440px]:py-[0.75rem] bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2]"
                                                        } focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none`}
                                                    errorCss="text-[0.75rem] text-red absolute lg:bottom-[-24px] md:bottom-[-18px] bottom-0"
                                                    toggleDropdown={toggleDropdown}
                                                    flags={flags}
                                                    customPhoneDropdownRef={customPhoneDropdownRef}
                                                    setSelectedCountryFlag={setSelectedCountryFlag}
                                                    setSelectedCountryDialCode={setSelectedCountryDialCode}
                                                    setToggleDropdown={setToggleDropdown}
                                                    handleChange={handleChange}
                                                    errorMessage={errorMessage}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-span-2 relative">
                                            <Budget
                                                fieldCss={`select-budget bg-[url('../assets/img/Path1.svg')] bg-no-repeat bg-[center_right_15px] ${"text-sm w-full text-black py-3 px-5 bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus-visible:border-[#C8CBD2]"
                                                    } 
                appearance-none w-full text-sm w-full text-black py-3 px-5 bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2] appearance-none`}
                                                errorCss="text-[0.75rem] text-red absolute lg:bottom-[-25px] bottom-0"
                                                formValue={formValue}
                                                currency={'USD'}
                                                handleChange={handleChange}
                                                errorMessage={errorMessage}
                                            />
                                        </div>
                                        <div className="form-group col-span-2 relative">
                                            <TextArea
                                                fieldCss={`align-top h-40 max-[1440px]:h-24 resize-none text-sm w-full text-black ${"py-3 px-5 bg-transparent rounded-[0.625rem] border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                                                    }`}
                                                errorCss="text-[0.75rem] text-red absolute lg:bottom-[-25px] bottom-0"
                                                formValue={formValue}
                                                handleChange={handleChange}
                                                errorMessage={errorMessage}
                                                name="message"
                                                placeholder="Message"
                                            />
                                        </div>
                                        <div className="form-group col-span-2">
                                            {/* {renderRecaptcha && ( */}
                                            <div
                                                className={`flex max-lg:justify-center justify-between pb-5 md:flex-nowrap flex-wrap relative pt-0 scale-90 origin-[0_0] md:transform-none`}
                                            >
                                                <Recaptcha
                                                    errorCss="absolute text-[0.75rem] text-red bottom-[1px]"
                                                    recaptchaRef={recaptchaRef}
                                                    handleToken={handleToken}
                                                    errorMessage={errorMessage}
                                                />
                                            </div>
                                            {/* )} */}
                                            <div className="flex flex-wrap items-center justify-between md:flex-nowrap">
                                                <p className="text-xs text-[#484848] leading-[1.125rem] mb-5 min-[991px]:mb-0 w-full text-left">By sending this form I confirm that I <br className="max-md:hidden" /> have read and accept the
                                                    <Link
                                                        to=""
                                                        className="font-gorditamedium italic underline"
                                                    > Privacy Policy</Link>
                                                </p>
                                                <button className="inline-flex items-center bg-white-200 justify-start inline-white text-black text-base rounded-3xl pl-[30px] pt-[12px] pb-[10px] relative pr-[52px] leading-[1.313rem] font-gorditamedium group ease-in duration-300 border border-black">
                                                    Submit
                                                    <span className="inline-flex bg-primary border-primary justify-center items-center rounded-full ml-[0.625rem]  h-[34px] w-[34px] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 absolute right-[5px] top-[5px]">
                                                        <img
                                                            src={ArrowWhite}
                                                            alt="RightArrow"
                                                            className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                                        />
                                                        <img
                                                            src={ArrowWhite}
                                                            alt="RightArrow"
                                                            className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                                        />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-[1.875rem] lg:py-[3.438rem] testimonial-sec">
                <div className="container">
                    <div className="w-full text-center lg:pb-10 pb-9">
                        <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic py-[0.563rem] px-[1.5rem] rounded-[6.25rem] mb-[0.625rem]">Testimonials</span></div>
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium lg:max-w-[59.375rem] mx-auto mb-5">
                            {post.testimonial_title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        {post.testimonial_p1 && <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">{post.testimonial_p1}</p>}
                        {post.testimonial_p2 && <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">{post.testimonial_p2}</p>}
                    </div>
                    <TestimonialSlider />

                    <div className="text-center mt-[1.875rem]">
                        <p className="text-lg md:text-xl lg:text-2xl mb-10">
                            {post.testimonial_p3.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </p>
                        <Link
                            to="/contact-us/"
                            className="inline-flex gap-5 items-center bg-white-200 justify-start inline-white text-black border border-white shadow-1xl text-base rounded-3xl pl-7 py-1 pr-[0.313rem] font-gorditamedium hover:bg-transparent hover:border-primary group"
                        >
                            {post.testimonial_button}
                            <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary border-2 border-primary justify-center items-center rounded-full ml-[0.625rem] ease-in duration-300 group-hover:bg-transparent relative">
                                <img
                                    src={animatedIconWhite}
                                    alt="Animated icon"
                                    className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 visible group-hover:opacity-0 group-hover:invisible transition-all ease-in duration-300"
                                />
                                <img
                                    src={animatedIcon}
                                    alt="Animated icon"
                                    className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section >

            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="title-sec text-center m-auto max-w-[59.375rem]">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                            {post.services.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem] mt-5">
                            {post.services.description.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[1.875rem] lg:gap-y-10 md:gap-x-10 lg:gap-x-[4.5rem] mt-10 md:mt-[3.125rem]">
                        {post.services.perks.map(({ icon, content }, idx) => (
                            <div key={idx} className="">
                                <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                    <img src={icon.publicURL} loading="lazy" alt={content[1]} />
                                </figure>
                                <span className={`inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] ${content[0]} text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]`}>{content[1]}</span>
                                <p className="text-[0.938rem] md:text-base leading-[1.625rem]">{content[2]}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-[3.438rem] overflow-hidden">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[59.375rem] m-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                            {post.website.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-[0.938rem] md:text-base leading-[1.875rem]">
                            {post.website.description}
                        </p>
                    </div>

                    {!post.slug.includes('denver') && <ul className="grid grid-cols-1 lg:grid-cols-4 gap-x-[1.688rem] gap-y-[1.875rem] mt-10 relative z-10 before:content-['*'] before:h-[0.063rem] before:absolute before:w-[500%] before:bg-black before:top-8 before:left-[50%] before:-translate-x-1/2 before:-z-10 max-lg:before:w-[0.063rem] max-lg:before:max-lg:h-full max-lg:before:top-0 max-lg:before:left-6 max-lg:before:translate-x-0">
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">1</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Planning</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">{post.website.planning}
                            </p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">2</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Development</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">{post.website.development}
                            </p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">3</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Testing</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">{post.website.testing}
                            </p>
                        </li>
                        <li className="relative pl-16 lg:pl-0 max-lg:bg-[#ffffff] bg-opacity-100">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">4</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Deployment</h4>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">{post.website.deployment}
                            </p>
                        </li>
                    </ul>}
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-[3.438rem]">
                <div className="container">
                    <div className="flex flex-wrap justify-between align-middle">
                        <div className="my-3 lg:max-w-[27.5rem] w-full">
                            <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium text-left">
                                {post.prefferedCompany.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                <span className="inline-block text-orangecol">.</span>
                            </h2>
                            <p className="text-base lg:text-lg lg:leading-[1.875rem] pb-7">
                                {post.prefferedCompany.description}
                            </p>
                            <Link
                                to="/contact-us/"
                                className="inline-flex items-center inline-white text-black border border-black text-base rounded-3xl pl-[1.313rem] py-1 pr-[0.313rem] font-gorditamedium hover:bg-transparent hover:border-primary group"
                            >
                                Let’s Talk
                                <span className="flex w-[1.875rem] h-[1.875rem] bg-primary border-2 border-primary justify-center items-center rounded-full ml-[0.625rem] ease-in duration-300 group-hover:bg-transparent relative">
                                    <img
                                        src={animatedIconWhite}
                                        alt="Animated icon"
                                        className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 visible group-hover:opacity-0 group-hover:invisible transition-all ease-in duration-300"
                                    />
                                    <img
                                        src={animatedIcon}
                                        alt="Animated icon"
                                        className="w-5 h-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all ease-in duration-300"
                                    />
                                </span>
                            </Link>
                        </div>
                        <div className="w-full lg:max-w-[calc(100%-27.5rem)] lg:pl-20">
                            {post.prefferedCompany.points.map(({ icon, content }, idx) =>
                                <div key={idx} className={`${idx !== post.prefferedCompany.points.length - 1 ? 'pb-10' : ''}`}>
                                    <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                        <img src={icon.publicURL} alt={content[1]} loading="lazy" />
                                    </figure>
                                    <h3 className={`inline-block italic py-2 px-[1.125rem] md:px-6 rounded-[6.25rem] ${content[0]} text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]`}>
                                        {content[1]}
                                    </h3>
                                    <p className="text-base leading-[1.625rem]">
                                        {content[2]}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {post.slug.includes('chicago') && <section>
                <div className="container">
                    <div className="py-10 lg:py-20">
                        <div className="title-sec text-center md:max-w-[47.5rem] m-auto mb-10 md:mb-12 lg:mb-[4.5rem]">
                            <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                                The Remarkable Development of Techuz
                                <span className="inline-block text-orangecol">.</span>
                            </h2>
                        </div>
                        <div className="grid md:grid-cols-2 lg:grid-cols-[28.5rem_1fr] gap-[1.875rem] lg:gap-x-[4.625rem] mb-[3.75rem] lg:mb-20">
                            <figure className="logo rounded-[1.25rem] overflow-hidden bg-[#F8F7F3] p-9 md:p-[3.125rem] h-[23.125rem] flex items-center justify-center">
                                <StaticImage
                                    src="../assets/img/clutchlogo.webp"
                                    alt="Clutch"
                                    className="block"
                                />
                            </figure>
                            <div className="">
                                <h3 className="font-blancomedium text-[1.75rem] md:text-4xl leading-[2.375rem] md:leading-[2.938rem] mb-[0.625rem] md:mb-5">
                                    Clutch has named Techuz as its <em className="text-primary">flagship web development company for 2023</em>, Chicago.
                                </h3>
                                <p className="mb-[0.625rem] md:mb-5">Clutch, a leading B2B market research and reviews company, named Techuz a 2023 top agency in Abbotsford in the following categories:</p>
                                <ul className="font-gorditamedium mb-[0.625rem] md:mb-5">
                                    <li className="mb-[0.625rem]"><em className="py-2 px-6 bg-[#F8F7F2] rounded-[7.875rem] inline-block">Customized web application development</em></li>
                                    <li className="mb-[0.625rem]"><em className="py-2 px-6 bg-[#F8F7F2] rounded-[7.875rem] inline-block">Single Page Applications (SPAs) and Progressive Web Applications (PWAs)</em></li>
                                    <li className="mb-[0.625rem]"><em className="py-2 px-6 bg-[#F8F7F2] rounded-[7.875rem] inline-block">Enterprise Portals and e-commerce Portals</em></li>
                                    <li className="mb-[0.625rem]"><em className="py-2 px-6 bg-[#F8F7F2] rounded-[7.875rem] inline-block">Great websites</em></li>
                                    <li className="mb-[0.625rem]"><em className="py-2 px-6 bg-[#F8F7F2] rounded-[7.875rem] inline-block">Web Tools and SaaS Solutions</em></li>
                                    <li className=""><em className="py-2 px-6 bg-[#F8F7F2] rounded-[7.875rem] inline-block">UI/UX Design</em></li>
                                </ul>
                                <p>Agencies are chosen for this distinction based off of several factors, including overall market presence and, most importantly, client feedback. Thrive has a 5.0 rating on Clutch.</p>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 lg:grid-cols-[28.5rem_1fr] gap-[1.875rem] lg:gap-x-[4.625rem]">
                            <figure className="logo rounded-[1.25rem] overflow-hidden bg-[#F8F7F3] p-9 md:p-[3.125rem]  h-[23.125rem] flex items-center justify-center">
                                <StaticImage
                                    src="../assets/img/otherlogo.webp"
                                    alt="National excellences united states"
                                    className="block"
                                />
                            </figure>
                            <div className="">
                                <h3 className="font-blancomedium text-[1.75rem] md:text-4xl leading-[2.375rem] md:leading-[2.938rem] mb-[0.625rem] md:mb-5">Techuz Named <em>2023 National Excellence Award Winner</em></h3>
                                <p className="mb-[0.625rem] md:mb-5">
                                    UpCity operates as an online marketplace dedicated to helping businesses discover leading B2B service providers. The platform's National Excellence Award recipients were selected through the UpCity Recommend Ability Rating, which uses digital indicators to assess provider reliability, accolades, and reputation UpCity CEO Dan Olson emphasized the importance of a National Excellence Award for Techuz in Chicago, 1999; backed by its flawless 5.0 rating. This reaffirms Techuz’s recognition on the platform for exceptional performance and superior service delivery.
                                </p>
                                <p>Techuz has a 5.0 rating on UpCity.</p>
                            </div>
                        </div>
                    </div>
                    <div className="banner-logo-slider pt-5 md:pt-[1.875rem] pb-[3.75rem] md:pb-20 lg:pb-[6.875rem] border-t border-[#DFDCD3]">
                        <BannerSlider bannerCount={5} swiperButtons="bullets" sliderClassWeb="!justify-center" />
                    </div>
                </div>
            </section>}

            <Technologies />

            <section className="py-10 md:py-12 lg:py-[3.438rem]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">Projects <em>powered by</em> our development team<span className="inline-block text-orangecol">.</span></h2>
                    </div>
                    <div className="mt-[2.313rem] md:mt-[3.125rem]">
                        {portfolio.map((project, idx) => (<PortfolioDetail key={`portfolio-${idx}`} {...project} />))}
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-[3.438rem]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium"><em>Industries</em> we serve<span className="inline-block text-orangecol">.</span></h2>
                    </div>
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-[1.875rem] mt-[2.313rem] md:mt-[3.125rem]">
                        <div className="bg-white-800 rounded-[1.25rem] overflow-hidden bg-[url('../assets/img/industry1.webp')] bg-no-repeat bg-center bg-cover h-[24.75rem] relative">
                            <em className="inline-block text-base font-gorditamedium py-[0.438rem] px-[1.875rem] absolute left-[1.375rem] bottom-[1.375rem] rounded-[6.25rem] bg-[#ffffff] z-10">EdTech</em>
                        </div>
                        <div className="bg-white-800 rounded-[1.25rem] overflow-hidden bg-[url('../assets/img/industry2.webp')] bg-no-repeat bg-center bg-cover h-[24.75rem] relative">
                            <em className="inline-block text-base font-gorditamedium py-[0.438rem] px-[1.875rem] absolute left-[1.375rem] bottom-[1.375rem] rounded-[6.25rem] bg-[#ffffff] z-10">Healthcare</em>
                        </div>
                        <div className="bg-white-800 rounded-[1.25rem] overflow-hidden bg-[url('../assets/img/industry3.webp')] bg-no-repeat bg-center bg-cover h-[24.75rem] relative">
                            <em className="inline-block text-base font-gorditamedium py-[0.438rem] px-[1.875rem] absolute left-[1.375rem] bottom-[1.375rem] rounded-[6.25rem] bg-[#ffffff] z-10">Marketplace</em>
                        </div>
                        <div className="bg-white-800 rounded-[1.25rem] overflow-hidden bg-[url('../assets/img/industry4.webp')] bg-no-repeat bg-center bg-cover h-[24.75rem] relative">
                            <em className="inline-block text-base font-gorditamedium py-[0.438rem] px-[1.875rem] absolute left-[1.375rem] bottom-[1.375rem] rounded-[6.25rem] bg-[#ffffff] z-10">Sports web app</em>
                        </div>
                    </div>
                </div>
            </section>

            <Faq faqData={post.faqs} />

            <Contact />
        </>
    )
}

export const query = graphql`
  query ($slug: String!, $portfolios_slug: [String]) {
    pseoWebDevelopmentCompanyJson(slug: { eq: $slug }) {
      slug
      seo_title
      seo_description
      title_1
      description_1
      testimonial_title
      testimonial_p1
      testimonial_p2
      testimonial_p3
      testimonial_button
      services {
        title
        description
        perks {
          icon {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            publicURL
          }
          content
        }
      }
      website {
        title
        description
        planning
        development
        testing
        deployment
      }
      prefferedCompany {
        title
        description
        points {
          icon {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
            publicURL
          }
          content
        }
      }
      faqs {
        key
        question
        answer
        listing
        conclusion
      }
    }
    allServicesPortfolioJson(filter: { slug: { in: $portfolios_slug } }) {
      portfolio: nodes {
        title
        slug
        subTitle
        description
        clutchLink
        industry
        country
        budget
        backgroundStyles
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          publicURL
        }
        featuredImageAlt
      }
    }
  }
`
export default PseoWebDevelopmentCompanyPage

export const Head: HeadFC = ({ location, data }) => {
    const { siteUrl } = useSiteMetadata()
    const { seo_title, seo_description } = data["pseoWebDevelopmentCompanyJson"]

    return (
        <SEO
            title={seo_title}
            canonical={`${siteUrl}${location.pathname}`}
            description={seo_description}
        />
    )
}
